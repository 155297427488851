@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


*{
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   scroll-behavior: smooth;
   /* overflow: hidden; */
   
}

::selection{
   background-color: #4EA3DB;
   color: #fff;
}


:root{
   --blue:#4EA3DB;
   --volume:rgb(150, 223, 255);
}