.height {
  height: calc(100vh - 65%);
}

@media (max-width: 600px) {
  .height {
    height: 360px;
  }
}

.height::-webkit-scrollbar {
  width: 10px;
}

.height::-webkit-scrollbar-thumb {
  background-color: #eaeaea;
  border-radius: 10px;
}

.height::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}


.typing{
  display: block;
  width: 100px;
  height: 40px;
  border-radius: 10px;
  margin-right: auto;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:0 5px;
  border-top-left-radius: 0;
  

}
.circle {
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #8d8d8d;
  margin: 3px;
}


.circle:nth-child(1){
  animation-delay: 0ms;
}
.circle:nth-child(2){
  animation-delay: 333ms;
}
.circle:nth-child(3){
  animation-delay: 666ms;
}
.scaling{

    animation: typing 1000ms ease-in-out infinite;
    animation-delay: 3600ms;
}

@keyframes typing {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

.toggle:hover ~ p{
  opacity: 1;
}



.message:hover .ellipsis{
  opacity: 100;
}

.ellipsis:hover {
  opacity: 100;
}