.scrollbar::-webkit-scrollbar {
  width: 10px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #eaeaea;
  border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; 
}

.hide-scrollbar {
  scrollbar-width: none; 
}

.editorClassName {
  /* border:  solid 1px #D0D5DD !important;
    border-radius: 5px !important; */
  box-shadow: 0.5px 0.5px 1px #ebebeb;
  max-height: 300px;
  overflow-y: auto;
  min-height: 300px;
  word-break: break-all;
}

.rdw-dropdown-optionwrapper,
.editorClassName::-webkit-scrollbar {
  width: 10px;
}

.rdw-dropdown-optionwrapper,
.editorClassName::-webkit-scrollbar-thumb {
  background-color: #eaeaea;
  border-radius: 10px;
}

.rdw-dropdown-optionwrapper,
.editorClassName::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.toolbarClassName {
  min-width: 100% !important;
  border-bottom: solid 1px #d0d5dd !important;
  border-radius: 5px !important;
  box-shadow: 0.5px 0.5px 1px #ebebeb;
  background-color: rgb(250, 250, 250) !important;
}

.rdw-fontfamily-wrapper {
  width: 13% !important;
}

.rdw-editor-wrapper {
  min-width: 100%;
}

.rdw-dropdown-optionwrapper::-webkit-scrollbar {
  width: 10px;
}

.rdw-dropdown-optionwrapper::-webkit-scrollbar-thumb {
  background-color: #eaeaea;
  border-radius: 10px;
}

.rdw-dropdown-optionwrapper::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

/* .w-full__input{
    top:7px !important;
  } */

textarea::placeholder {
  /* text-align: center; */
  padding: auto 0 !important;
  display: table-cell;
  vertical-align: middle !important;
  margin-top: 10% !important;
}

textarea{
  padding-left: 0.4rem;
}


textarea:focus {
  outline: none;
  max-height: 100%;
  max-width: 100% !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
}

.w-full__suggestions {
  margin: 0 !important;
  z-index: 1000;
  margin-left: 2px;
  top: -1.4rem !important;
  left: 10% !important;
}

.w-full__suggestions ::-webkit-scrollbar {
  width: 7px;
}

.w-full__suggestions ::-webkit-scrollbar-thumb {
  background-color: #eaeaea;
  border-radius: 10px;
}

.w-full__suggestions ::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.w-full__suggestions__list {
  z-index: 10;
  background: white;
  border: solid 1px var(--blue);
  /* background: var(--blue); */
  width: 10rem;
  overflow-x: hidden !important;
  color: #4EA3DB;
  max-height: 45px !important;
  overflow-y: scroll !important;
  border-radius: 2px;
}
.mention {
  color: blue; 
}

.w-full__highlighter {
  height: auto !important;
}

textarea::-webkit-scrollbar {
  width: 10px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #eaeaea;
  border-radius: 10px;
}

textarea::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.level_0 {
  border: 0;
}

.level_1 {
  border: solid 2px var(--volume);
}

.level_2 {
  border: solid 4px var(--volume)
}

.level_3 {
  border: solid 6px var(--volume);

}

.level_4 {
  border: solid 8px var(--volume);
}

.level_5 {
  border: solid 10px var(--volume);
}

.rdw-text-align-dropdown {
  z-index: 5 !important;
}

.rdw-list-dropdown {
  z-index: 5 !important;
}

.sound_animation {
  animation-name: sound;
  animation-duration: 5s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
}

.rdw-emoji-modal {
  width: 7.5rem !important; /* Reduce the modal width */
  height: 5rem !important; /* Reduce the modal height */
}
.rdw-emoji-modal .rdw-emoji-icon {
  width: 17px; /* Adjust emoji size */
  height: 17px;
  font-size: 17px;
}

@keyframes sound {
  0% {}

  25% {}

  50% {}

  60% {
    border: solid 10px rgba(221, 221, 221, 0.5);
  }

  75% {
    border: solid 12px rgba(221, 221, 221, 0.5);
  }

  100% {
    border: solid 2px rgba(221, 221, 221, 0.5);
  }
}