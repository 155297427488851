/* :root {
  --selected-icon-bg-color: #dfdfdf;
} */

.container {
  position: relative;
  margin: 0px;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow: hidden;
}

/* #whiteboardContainer {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
} */

.btnGroup button {
  background: transparent;
  border: 2px solid #636060;
  margin: -1px;
  color: black;
  padding: 11px 14px;
  cursor: pointer;
  float: left;

}

@media (max-device-width: 1024px) {
  .btnGroup button {
    font-size: 3em;
    height: 135px;
    width: 150px;
  }

  .minGroup {
    width: 80px;
  }
}

@media (min-device-width: 1024px) {
  .btnGroup button {
    font-size: 1.2em;
    height: 45px;
    width: 50px;
  }

  .minGroup {
    width: 25px;
  }
}



.groupDisabled {
  background: repeating-linear-gradient(45deg,
      rgba(255, 166, 0, 0.366),
      rgba(255, 166, 0, 0.366) 10px,
      rgba(255, 166, 0, 0.666) 10px,
      rgba(255, 166, 0, 0.666) 20px);
}

/*
   * Deactivate all pointer events on all the children
   * of a group when it's disabled.
   */
.groupDisabled>* {
  pointer-events: none;
}

/* Clear floats (clearfix hack) */

.btnGroup:after {
  content: "";
  clear: both;
  display: table;
}

/* Add a background color on hover */

.btnGroup button:hover {
  background-color: #9a9a9a;
}




.btnGroup {
  background-color: #808080ab;
  margin-left: 5px;
  margin-bottom: 5px;
  float: left;
  position: relative;
}

.whiteboardTool.active:not(:disabled) {
  background: var(--selected-icon-bg-color);
}

/* #whiteboardThicknessSlider {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 3px;
    background: transparent;
    outline: none;
    opacity: 1;
    -webkit-transition: opacity 0.15s ease-in-out;
    transition: opacity 0.15s ease-in-out;
  } */



/* not use  */
button {
  outline-width: 0;
}

/* not use  */
button::-moz-focus-inner {
  border: 0;
}

/* not use  */
.modalBtn {
  padding: 5px;
  border-radius: 5px;
  border: 0px;
  min-width: 50px;
  cursor: pointer;
}

/* not use  */
#displayWhiteboardInfoBtn.active {
  background: var(--selected-icon-bg-color);
}

/* not use  */
#whiteboardInfoContainer {
  position: absolute;
  bottom: 10px;
  right: 10px;
}