 /* not use  */
.drag-wrapper {
    width: 200px;
    cursor: move;
    padding: 50px;
    text-align: center;
    background: #e5e5e5;
}

.stickyNote {
    box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
    overflow-y: scroll;
}

.stickyNote::-webkit-scrollbar {
    display: none;
}


.textBox.active {
    border: 1px dashed gray;
}

.textBox>.removeIcon,
.textBox>.moveIcon {
    display: none;
}

.textBox.active>.removeIcon,
.textBox.active>.moveIcon {
    display: block;
}