.custom_loader{
    width: 180px;
    height: 180px;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#4EA3DB 94%,#0000) top/16px 16px no-repeat,
    conic-gradient(#0000 30%,#4EA3DB);

  animation:s3 1s infinite linear;
}

@keyframes s3{ 
    100%{transform: rotate(1turn)}
  }
  