.dynamic_height{
    height: calc(100vh - (5*24px) + 9px);
}

.webcam {
    /* margin-top: 30px; */
    animation: pulse 2s ease-out infinite;
    position: relative;
  }
  .webcam::after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 50%;
    position: absolute;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0px #4EA3DB,
        0 0 0 0px #4EA3DB;
    }
    50% {
      transform: scale(0.95);
    }
    100% {
      box-shadow: 0 0 0 15px rgba(0, 210, 255, 0), 0 0 0 30px rgba(0, 210, 255, 0);
    }
  }